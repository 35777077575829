.paragraph-sosyal-giris span {
  color: #246447; /* Koyu yeşil bir renk */
}

.paragraph-sosyal-giris {
  font-size: 15px; 
}

.small-paragraph {
  font-size: 14px; /* Varsayılan değer */
  line-height: 1.5;
}

@media (max-width: 768px) {
  .small-paragraph {
    font-size: 13px; /* Daha küçük ekranlar için */
  }
}

.light-red {
  color: #D8484F;
}

.sosyal .accordion-button:not(.collapsed) {
  color: white !important;
  background-color: #15746f !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 #15746f !important;
}

/* Accordion butonunun açıldığında görünen ok işaretinin rengini ayarlama */
.sosyal .accordion-button:not(.collapsed)::after {
  color: #ffffff !important; /* İşaretin rengini beyaz yap */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23ffffff" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>'); /* Varsayılan Bootstrap okunu beyaz renkle yeniden boyar */
  filter: brightness(0) invert(1); /* Renkleri ters çevirir, SVG içindeki renk kodunu değiştirin */
}

.sosyal .accordion-body-text-sosyal {
  font-size: 14px; 
}

.info-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.info-box {
  width: 200px;
  height: 130px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;  /* Dikey ortalama */
  align-items: center;  /* Yatay ortalama */
  border-radius: 8px;
  color: black;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease-in-out;
  
}

.box1 {
  background-color: rgba(255, 255, 255, 1);
  
}


.box2 {
  background-color: rgba(255, 255, 255, 0.5);
}

.info-box-container:hover .info-box {
  transform: scale(1.05);
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;

  text-align: center;
}

.count {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin: 0;

}

.description {
  font-size: 14px;
  text-align: center;
  margin: 0;
}


dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
  list-style: none;
}

@media (max-width: 700px) {
  .info-box {
    width: 150px;
    height: 120px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Dikey ortalama */
    align-items: center;  /* Yatay ortalama */
    border-radius: 8px;
    color: black;
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.5s ease-in-out;
    
  }
}

@media (max-width: 992px) {
  .ilk-giris{
    margin-top: -4rem!important;
  }
  .sosyal-baslik{
    font-size: 1.5rem!important;
  }

}