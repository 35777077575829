/* styles.css */
.spacer {
    height: 50px;
  }
  
  @media (max-width: 700px) {
    .spacer {
      height: 20px;
    }
  }
  