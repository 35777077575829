.image-container {
    position: relative;
    overflow: hidden;
    height: 60vh; 
    width: 100%;
  }
  
  .image-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }
  
  .scroll-wrapper {
    height: 100%;
    overflow-y: scroll; /* Dikey kaydırma */
    position: relative;
  }
  
  .scroll-image {
    width: 100%;
    height: auto;
  }
  
  .mask-overlay {
    position: absolute;
    top: -4rem;
    left: 0;
    width: 100%;
    height: 6rem; /* Maskenin yüksekliğini 2rem olarak ayarladık */
    background-image: url('/src/assets/img/mask.png'); /* Maskeyi uyguluyoruz */
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2; /* Üstte görünmesini sağlamak için */
  }
  
  .centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background: linear-gradient(to right,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    width: 80%; /* Genişliği ayarlayarak daha okunabilir hale getir */
    font-size: 44px; /* Yazı boyutunu ayarlayın */
    z-index: 3; /* En üstte görünmesini sağlamak için */
  }
  