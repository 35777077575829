@media (max-width: 991px) {
  .advanced-table-container {
    transform: scale(0.51);
    transform-origin: top left;
    width: 200%;
    height: auto;
    overflow: hidden;
  }

  .advanced-table-wrapper {
    height: 330px;

    overflow: hidden;
    margin-bottom: -1200px; 
  }
}

.number-range-slider {
  height: 8px;
  border-radius: 4px;
  background: linear-gradient(to right, #69b79e, #3f8b68);
  position: relative;
  margin: 10px 0;
}

.number-range-slider .thumb {
  height: 25px;
  width: 25px;
  background-color: white;
  border: 2px solid #3f8b68;
  border-radius: 50%;
  cursor: grab;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0px;
  color: #3f8b68;
  font-weight: bold;
}

.number-range-slider .thumb:hover {
  background-color: #3f8b68;
  color: white;
}

.number-range-slider .track {
  top: 0;
  bottom: 0;
  background: #d7d7d7;
  border-radius: 4px;
}

