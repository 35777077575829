

.flourish-container2 {
  width: 100%;
  height: 635px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1338px) {
  .flourish-container2 {
  position: relative;

    width: 100%;
    height: 470px;
    padding: 0;
  }

  .flourish-iframe {
    position: absolute;
    top: -50px!important;
    left: -10px!important;
 
    
  }
}

.flourish-iframe {
  position: absolute;
  top: -50px; /* Adjust as needed to center the content vertically */
  left: -40px; /* Adjust as needed to center the content horizontally */
  width: 1150px; /* Adjust as needed to zoom the content */
  height: 800px; /* Adjust as needed to zoom the content */
  border: none;
  clip-path: inset(90px 68px 70px 68px); /* Top, right, bottom, left */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent; /* Görünmez katman */
  z-index: 1; /* İframe'in üstünde */
  pointer-events: none; /* Kullanıcı etkileşimini engeller */
}

@media (max-width: 600px) {
  .flourish-container {
    width: 100%;
    height: 300px; /* Adjust as needed for mobile view */
  }

  .flourish-iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: none; /* Disable clipping for mobile view */
  }

  .overlay {
    display: none; /* Mobil görünümde katmanı gizle */
  }
}

#onetrust-consent-sdk #onetrust-banner-sdk {
  display: none !important;
}

#onetrust-consent-sdk {
  display: none !important;
}

#onetrust-banner-sdk .ot-sdk-container {
  display: none !important;
}

.ot-sdk-container {
  display: none !important;
}

.ot-sdk-row {
  display: none !important;
}
