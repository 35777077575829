@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --color1: rgb(0, 210, 49);
  --color2: rgb(17, 46, 59);
  --color3: rgb(2, 108, 26);
  --color4: rgba(0, 210, 49, 0.4);
  --color5: rgba(17, 46, 59, 0.4);
  --color6: #362FD9;
  --color7: #1AACAC;
  --color8: #2E97A7;
  --color9: #EEEEEE;
}

body{
  font-family: Raleway;
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
}