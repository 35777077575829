.boyut{
    height: 330px;
}


@media (max-width: 992px) {
    .boyut{
        height: 190px;
    }
  }


.yukseklik{
    height: 560px;
 
}

@media (max-width: 992px) {
    .yukseklik{
        height: 250px;
        margin-top: -5rem;
    }
  }
  @media (min-width: 1400px) {
    .yukseklik{
        height: 600px;
    };
  }

  @media (min-width: 1550px) {
    .yukseklik{
        height: 650px;
    };
  }

  .locker-map{
    height: 530px;
  }

  @media (max-width: 992px) {
    .locker-map{
        height: 160px;
      }
  }
 
  @media (min-width: 1590px) {
    .locker-map{
        height: 580px;
      }
  }





  .locker-map2{
    height: 530px;
  }

  @media (max-width: 992px) {
    .locker-map2{
        height: 140px;
      }
  }
  @media (min-width: 1590px) {
    .locker-map2{
        height: 580px;
      }
  }


  .kapatilan-boyut{
    height: 626px;
  }

  @media (max-width: 992px) {
    .kapatilan-boyut{
        height: 232px;
      }
  }
  @media (min-width: 1475px) {
    .kapatilan-boyut{
        height: 690px;
      }
  }
  @media (min-width: 1600px) {
    .kapatilan-boyut{
        height: 720px;
      }
  }



  .sorumlu-boyut{
    height: 360px;
  }

  @media (max-width: 992px) {
    .sorumlu-boyut{
        height: 200px;
        margin-top: -3rem;
      }
  }