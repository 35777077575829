.iframe-container {
    overflow: hidden;
    height: 380px;
    position: relative;
  }
  
  .looker-iframe {
    height: 550px;
  }
  
  @media (max-width: 991px) {
    .iframe-container {
      height: 240px;
    }
  
    .looker-iframe {
      height: 240px;
    }
  }
  
  @media (min-width: 1450px) {
    .iframe-container {
      height: 430px;
    }
  
    .looker-iframe {
      height: 650px;
    }
  }