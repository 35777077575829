@keyframes typing {
    from {
        width: 0;
        border-right-color: black;
    }
    to {
        width: 100%;
        border-right-color: transparent;
    }
}

.typing-container {
    font-family: 'Roboto', Courier, monospace;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid transparent;
    max-width: max-content;
    animation: typing 4s steps(30) 1s forwards;
    text-align: center;
    margin: 0 auto;
}

.kit, .goz {
    display: inline;
}

.kit {
    color: black;
}

.goz {
    color: red;
}

#text-animation {
    font-size: 24px;
    font-weight: bold;
    font-family: Arial, sans-serif;
    text-align: center;
}

@media (max-width: 991px) {
    .responsive-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    
    .responsive-col h3 {
        margin-bottom: 10px;
    }
    
    .responsive-col .type-animation-wrapper {
        margin-top: 10px;
        height: 4em; /* Yüksekliği sabitle */
        overflow: hidden; /* Taşan içeriği gizle */
        display: flex;
        align-items: center;
    }
}
