@media (max-width: 991px) {
    .container-fluid {
      padding: 0 !important;
      margin-top: -1rem!important;
    }
    .mb-col-space {
      margin-bottom: 30px; 
    }
 
  }

  .container-fluid {
    margin-top: -4.2rem;
  }

