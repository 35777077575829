@keyframes typing {
    from {
        width: 0;
        border-right-color: black;
    }
    to {
        width: 100%;
        border-right-color: transparent;
    }
}

#text-animation {
    font-size: 24px;
    font-weight: bold;
    font-family: Arial, sans-serif;
    text-align: center;
}

.responsive-col2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.responsive-col2 h3 {
    margin-bottom: 10px;
}

.type-animation-wrapper2 {
    margin-top: 10px;
    height: 4em; /* Yüksekliği sabitle */
    overflow: hidden; /* Taşan içeriği gizle */
    display: flex;
    align-items: center;
}

@media (max-width: 991px) {
    .responsive-col2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    
    .responsive-col2 h3 {
        margin-bottom: 10px;
    }
    
    .responsive-col2 .type-animation-wrapper2 {
        margin-top: 10px;
        height: 6em; /* Yüksekliği sabitle */
       font-size: 14px;
        display: flex;
        align-items: center;
    }
}
