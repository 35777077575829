@media (min-width: 992px) {
    .admin-navbar img{
        height: auto;
        margin-bottom: 0.5rem;
    }
  }



.navbar-menu{
    padding:0.5rem 0rem;
    font-size: 0.95rem;
    font-weight: 300;
}

.admin-navbar ul{
    margin:0;
    padding: 0;
    display:inline-block;
}

.admin-navbar ul li{
    text-align: left;
    line-height: 2.5rem;
}

.admin-navbar .nav-link{
    color: white !important;
    margin-bottom: -0.2rem;
   
}

.admin-navbar .nav-link svg{
    font-size: 1.5rem;
    margin-top: -4px;
}



.navbar-dark .navbar-nav .nav-link.active, 
.navbar-dark .navbar-nav .show>.nav-link {
    background-color: white;
    border-radius: 0.3rem;
    color: var(--color2)!important;
}


.dropdown-divider{
    background-color: #fff!important;
    height: 2px!important;
}

@media(min-width: 992px){

    .admin-navbar .navbar-nav{
        flex-direction: column !important;
    }

    .admin-navbar > .container{
        display: block !important;
        padding: 1rem 2rem;
    }
}

@media(max-width: 400px){
    .img-fluid {
        width: 80%;
    }
}

@media(max-width: 282px){
    .navbar-brand {
        width: 60%;
    }
}

@media(max-width: 990px){
    .admin-navbar img{
        height: 40px;
    }

}



.video-background {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
  }