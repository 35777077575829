.paragraph-sinirdisi-giris span {
  color: #246447; /* Koyu yeşil bir renk */
}

.paragraph-sinirdisi-giris {
  font-size: 15px; 
}


.small-paragraph {
  font-size: 14px; /* Varsayılan değer */
  line-height: 1.5;
}

@media (max-width: 768px) {
  .small-paragraph {
      font-size: 13px; /* Daha küçük ekranlar için */
  }
}


  .light-red{
    color: #1A1A1A;
  }


  .sinirdisi .accordion-button:not(.collapsed) {
    color: white!important;
    background-color: #1A1A1A!important;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 #1A1A1A !important;
}

/* Accordion butonunun açıldığında görünen ok işaretinin rengini ayarlama */
.sinirdisi .accordion-button:not(.collapsed)::after {
  color: #ffffff !important; /* İşaretin rengini beyaz yap */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23ffffff" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>'); /* Varsayılan Bootstrap okunu beyaz renkle yeniden boyar */
  filter: brightness(0) invert(1); /* Renkleri ters çevirir, SVG içindeki renk kodunu değiştirin */
}

.sinirdisi .accordion-body-text-olumler {
  font-size: 14px; 
}



.i-title {
  height: 50px;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
}

.i-title-wrapper {
  height: 150px; /* 3 items * 50px each */
  animation: move 8s linear infinite;
}

@keyframes move {
  0% {
    transform: translateY(0);
  }
  33.33% {
    transform: translateY(0);
  }
  41.66% {
    transform: translateY(-50px);
  }
  74.99% {
    transform: translateY(-50px);
  }
  83.33% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(-100px);
  }
}

.i-title-item {
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #ff4c1f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.first-data {
  margin-bottom: 4rem;
}

.second-data .i-title-item {
  color: #ffffff;

}

.header-first{
  color: #ff4c1f;

}


.desktop-margin-top {
  margin-top: -8rem;
}

@media (max-width: 992px) {

  .second-data .i-title-item {
    color: #cba80a;
  
  }

  .second-data h4 {
    color: #cba80a!important;
  
  }

  .desktop-margin-top {
    margin-top: 0rem!important;
   
    
  }
  .desktop-margin-top h4{
    font-size: 1rem;
   
  }
  .ilk-giris{
    margin-top: -4rem!important;
  }
  .first-data{
    margin-bottom: 0rem!important;
  }
  
  .second-data{
    margin-top: 1rem!important;
  }
}

.first-data{
  margin-bottom: 4rem;
}

.second-data{
  margin-top: 4rem;
}


@media (max-width: 768px) {
  .img-fluid {
    width: 100%;
  }
  .alt-baslik{
    margin-top: 1rem;
    font-size: 1.3rem;
  }
  .iade-gorsel{
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
  .interpol-resmi{
    margin-top: -1.5rem!important;
    margin-bottom: 1rem;
  }
}

.iade-gorsel{
  margin-top: 3rem;
}