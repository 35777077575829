.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .iframe-youtube {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }
  
  @media (max-width: 991px) {
    .video-container {
      border-radius: 0;
      box-shadow: none;
      height: auto;
      width: 100%; /* Genişliği tam yap */
      display: flex;
      justify-content: center; /* Ortalamak için */
     
    }
  
    .iframe-youtube {
      width: 80vw; /* Genişliği daha da daralt */
      height: calc(80vw * 0.5625); /* 16:9 oranını korumak için */
      border-radius: 0;
    }

    .card-bosluk{
        margin-bottom: 1rem;
    }
  }
  